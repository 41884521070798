import React from "react";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ to, icon, text }) => (
    <NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to={to}>
        <li className="nav-item select mt-2 mb-1 ms-3">
            <span className="h6">
                <i className={`fa-solid fa-${icon}`}></i> &nbsp; {text}
            </span>
        </li>
    </NavLink>
);

const B2GSidebar = () => {

    return (
        <>
            <nav id="sidebar" className="sidebarhome bg-left-navabr">
                <ul className="navbar-nav scrollBar ms-auto mb-lg-0 d-flex flex-column text-left mt-1">
                    {/* <li className="menu-title h6 mt-1 mb-1">Dashboard</li> */}
                    <SidebarLink to="/" className="mt-2" icon="home" text="Dashboard" />
                    {/* <li className="menu-title h6 mt-1 mb-1">B2G</li> */}
                    <SidebarLink to="/b2g/states" text="States" />
                    <SidebarLink to="/b2g/districts" text="Districts" />
                    <SidebarLink to="/b2g/communities" text="Community's" />
                    <SidebarLink to="/b2g/wards" text="Wards" />
                    {/* <SidebarLink to="/b2g/communityAdmin" icon="4" text="Community Admin" /> */}
                    <SidebarLink to="/b2g/admin" text="Admin" />
                    <SidebarLink to="/b2g/survey" text="Survey" />
                    <SidebarLink to="/b2g/driverLive" text="Driver live" />
                    {/* <SidebarLink to="/b2g/surveyDetail" icon="7" text="survey deatail" /> */}
                    <SidebarLink to="/b2g/collections" text="Collection" />
                    <SidebarLink to="/b2g/complains" text="Complain" />
                    {/* <SidebarLink to="/b2g/CollectionDriver" text="Driver" />
                    <SidebarLink to="/b2g/CollectionVehicle" text="Vehicle" />
                    <SidebarLink to="/b2g/CollectionSupervisor" text="Supervisor" />
                    <SidebarLink to="/b2g/CollectionIEC_Person" text="IEC-Person" /> */}
                </ul>
            </nav>
        </>
    )
}

export default B2GSidebar;