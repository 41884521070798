import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import { useGetProfileQuery } from '../../profile/profileSlice'
import { logout } from "../../auth/authSlice";
// import {ecowrapLogo} from '../../../images/ecowrap-logo.png'
const B2GNavbar = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [profileData, setProfileData] = useState({ name: "", role_type: "" })
    const handleLogout = () => {
        dispatch(logout())
    }

    const {
        data: profile,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetProfileQuery('getProfile')

    if (isError && error.message === '203') {
        dispatch(logout())
    }
    useEffect(() => {
        if (isSuccess && profile && profile.ids && profile.ids.length > 0) {
            const firstProfileId = profile.ids[0];
            const firstProfile = profile.entities[firstProfileId];
            setProfileData({ name: firstProfile.name, role_type: firstProfile.role_type });
        }
    }, [isSuccess, profile])
    return (
        <>
            <nav className={`navbar-b2g navbar navbar-expand-lg ${secureLocalStorage.getItem("selectedTheme") === "dark" ? "bg-top-navbar-dark" : "bg-top-navbar-light"}`}>
                <div className="container-fluid">
                    <div className="navbar-brand ecowrap_logo">
                        <a href="/">
                            <img src="assets/images/ecowrap-logo.png" style={{ width: "180px" }} alt="ecowrap-logo" />
                            {/* <img src={ecowrapLogo} style={{ width: "180px" }} alt="ecowrap-logo" /> */}
                        </a>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginRight: '100px', width: '50px' }}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <div className="d-flex">
                            <ul className="navbar-nav mb-2 mb-lg-0 nav nav-pills ">
                                <li className="nav-item ms-2 me-2">
                                    <Avatar round={true} size="35"
                                        name={profileData.name}
                                    />
                                </li>
                                <div className="dropdown">
                                    <div className="nav-item dropdown-toggle text-white" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="text-left user-select-all">
                                            <span className="fw-bold">{profileData.name}</span>
                                        </div>
                                        <span className="fw-lighter">{profileData.role_type}</span>
                                    </div>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <button className="dropdown-item" onClick={() => navigate("/b2g/profile")}>
                                                <i className="fa-solid fa-user"></i> &nbsp; Profile
                                            </button>
                                        </li>
                                        {/* <li>
                                            <button className="dropdown-item" onClick={addTeamMembers}>
                                                <i className="fa-solid fa-add"></i> &nbsp; Add Team Member
                                            </button>
                                        </li> */}
                                        {/* <li>
                                            <button className="dropdown-item" onClick={addDrivers}>
                                                <i className="fa-solid fa-add"></i> &nbsp; Add Driver
                                            </button>
                                        </li> */}
                                        <li>
                                            <button className="dropdown-item" onClick={handleLogout}>
                                                <i className="fa-solid fa-right-from-bracket"></i> &nbsp; Logout
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                {/* <li className="nav-item ms-2 me-2">
                                    <DarkMode />
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default B2GNavbar;