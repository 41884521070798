import React from "react";
import './SpinnerForPage.css'

const SpinnerForPage = () => {
    return (
        <>
            {/* <div className="text-center">
                <img className="my-5" src="/loader-page.gif" alt="spinner-for-page" />
            </div> */}
            <div className="loader_home">
                <div class="loader"></div>
            </div>

        </>
    )
}

export default SpinnerForPage;