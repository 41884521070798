import { useComplainViewModel } from './ComplainController';
import { useDispatch } from 'react-redux';
import { logout } from '../auth/authSlice';
import { useState } from "react";
// import moment from 'moment';
function ComplainList() {
    const {
        handleReset,
        handleChange,
        handleSearch,
        handleNavigateToDetail,
        complainList,
        isComplainListLoading,
        isComplainListError,
        complainListError,
        statusList,
        // isStatusListLoading,
        isStatusListError,
        statusListError,
        searchParamsLocal,
        // selectAllComplain
    } = useComplainViewModel();

    const dispatch = useDispatch()
    const [filterSearch, setFilterSearch] = useState({})

    if ((isComplainListError && complainListError?.statusCode === '401') || (isStatusListError && statusListError?.statusCode === '401')) {
        dispatch(logout)
    } 

    return (
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-3 p-3'>
                    <h4>Complain</h4>
                </div>
            </div>
            <div className=' w-75 m-auto mb-3 row d-flex justify-content-center gap-5'>
                <div className="w-100 d-flex justify-content-center gap-5">
                      <select
                        aria-label="Default select example"
                        className=' form-select'
                        name='status'
                        onChange={handleChange}
                        value={searchParamsLocal.status}
                    >
                        <option value={""}>--</option>
                        {
                            statusList && statusList.length > 0 && statusList.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })
                        }
                    </select>
                    <div className='col-3 d-flex gap-5'>
                        <button className='btn bg-left-navabr  text-light '
                            onClick={handleSearch}>
                            Search
                        </button>
                        <button className='btn bg-left-navabr  text-light'
                            onClick={handleReset}
                        >
                            Reset</button>
                    </div>
                </div>


            </div>
            <div className='row m-5'>
                <div className='col'>
                    {isComplainListLoading && (
                        <p>Loading...</p>
                    )}

                    {isComplainListError && (
                        <p>Error: {complainListError}</p>
                    )}

                    {!isComplainListLoading && !isComplainListError && (
                        <table className='border table p-2'>
                            <thead>
                                <tr className='bg-left-navabr '>
                                    <th className='text-light'>#</th>
                                    <th className='text-light'>Name</th>
                                    <th className='text-light'>Number</th>
                                    <th className='text-light'>Complain</th>
                                    <th className='text-light'>Status</th>
                                    {/* <th>Creatation Date</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(complainList?.entities).map((complaint, index) => (
                                    <tr key={complaint.c_id}
                                        onClick={() => (handleNavigateToDetail(complaint.c_id))}
                                    >
                                        <td>{index + 1}</td>
                                        <td >{complaint.name}</td>
                                        <td>{complaint.number}</td>
                                        <td>{complaint.text.slice(0, 13) + '...'}</td>
                                        <td>{complaint.c_status}</td>
                                        {/* <td>{moment(complaint.create_at).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss')}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ComplainList;
