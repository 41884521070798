import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const driverAdaptor = createEntityAdapter({
    // selectId: (entity) => entity.community_admin_id,
})

const initialDriver = driverAdaptor.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDriver: builder.query({
            query: () => '/b2g/eco-admin/driver',
            transformResponse: responseData => {
                if (responseData?.status === false) {
                    throw new Error(responseData?.statusCode)
                }
                return driverAdaptor.setAll(initialDriver, responseData?.data)
            },
            providesTags: (result, error, arg) => {
                if (error || !result?.ids) {
                    return [{ type: 'B2gDriver', id: 'List' }, []];
                } else {
                    return result.ids.map(id => ({ type: 'B2gDriver', id }));
                }
            }
        }),

        addNewDriver: builder.mutation({
            query: body => ({
                url: '/b2g/eco-admin/driver',
                method: 'POST',
                body: body
            }),
            invalidatesTags: [
                { type: 'B2gDriver', id: 'List' }
            ]
        })
    })
})

export const {
    useGetDriverQuery,
    useAddNewDriverMutation,
} = extendedApiSlice
