import { createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../api/apiSlice"


const collectionAdaptor = createEntityAdapter({
    selectId: (item) => (item.assignment_id)
})

const initialCollection = collectionAdaptor.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCollectionList: builder.query({
            query: (page) => {
                // const queryParams = []
                // if (searchParams) {
                //     queryParams.push(`assignment_last_update=${searchParams.assignment_last_update}`)
                // }
                let url = `/b2g/eco-admin/collection?page=${page}`
                // if (queryParams.length > 0) {
                //     url = `/b2g/eco-admin/collection` + '?' + queryParams.join('&')
                // }
                // console.log('page', page);

                return url
            },
            transformErrorResponse: (err) => {
                // console.error(err)
                return err
            },
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    // console.log(response.data[0]);

                    const { totalCollections, totalPages, currentPage, data } = response.data[0];

                    console.log({
                        totalCollections,
                        totalPages,
                        currentPage,
                        data
                    })
                    return {
                        totalCollections,
                        totalPages,
                        currentPage,
                        entities: data
                    }
                } else {
                    throw new Error(response.data)
                }
            },
            providesTags: (result, error, args) => {
                if (error) {
                    return []
                }
                if (!error && result) {
                    return [{ type: 'collectionB2g', id: 'List' },]
                }
            }
        }),
      
        getCollectionDetails: builder.query({
            query: (searchParams) => {
                const arr = []
                let url = `/b2g/eco-admin/collection`
                if (searchParams && searchParams.collection_date) {
                    arr.push(`assignment_last_update=${searchParams.collection_date}`)
                    // arr.push(`assignment_last_update=2024-09-23`)
                }
                if (searchParams && searchParams.assignment_id) {
                    url = url + `/${searchParams.assignment_id}`
                    // url = url + `/13`
                }
                if (arr.length > 0) {
                    url = url + '?' + arr.join('&')
                }
                // console.log(url);

                return url
            },
            transformErrorResponse: (error) => {
                // console.error(error)
                return error
            },
            transformResponse: (response) => {
                if (response.statusCode === 200) {
                    // console.log('response collectionDetails', response);

                    return response.data
                } else {
                    throw new Error(response)
                }

            },
            providesTags: ['collectionDetails', 'List']
        })
    })
})

// export const { selectAll: selectAllComplain } = complainAdaptor.getSelectors((state) => state.complaints);

export const { useGetCollectionListQuery, useGetCollectionDetailsQuery } = extendedApiSlice