import React from 'react';

const CollectionVehicle= () => {
    return (
        <div>
           CollectionVehicle
        </div>
    );
};

export default CollectionVehicle;
