import React from 'react';
import { useGetDriverQuery } from "./driverSlice";


const CollectionDriver= () => {
    const {
        data: drivers,
        isLoading,
        isSuccess,
        isError,
        error
    } =useGetDriverQuery('getDrivers')
    console.log('====================================');
    console.log(drivers);
    console.log('====================================');
    return (
        <div>
           <div className="row">
                    <div className="col-md-6 text-left">
                        <h3 className="page-title">B2g  Driver</h3>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <span className="breadcrumb-active">B2g Driver</span>
                            </li>
                        </ul>
                    </div>

                </div>
               
                <div className="card card-dark mt-4">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"> Number</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Role</th>
                                    </tr>
                                </thead>
                                <tbody>             
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default CollectionDriver;
