import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { useGetCollectionDetailsQuery } from './collectionRtk';
import '../driverLive/liveDriver.css';

const DEFAULT_CENTER = { lat: 26.8954906, lng: 75.839768 };
const DEFAULT_ZOOM = 10;
const MAP_API_KEY = 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8'; // Replace with your valid API Key

const CollectionRoute = () => {
    const [mapCenter, setMapCenter] = useState(DEFAULT_CENTER);
    const [mapInstance, setMapInstance] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [boundary, setBoundary] = useState([]);
    const [path, setPath] = useState([]);
    const [isAnimating, setIsAnimating] = useState(false);
    const [animationIntervalId, setAnimationIntervalId] = useState(null);
    const [truckMarker, setTruckMarker] = useState(null);
    const location = useLocation();

    const {
        data: collectionDetails,
        isLoading: isCollectionDetailsLoading,
        isError: isCollectionDetailsError,
    } = useGetCollectionDetailsQuery({
        assignment_id: location.state.id,
        collection_date: location.state.collection_date,
    });

    // Set path and boundary from fetched data
    useEffect(() => {
        if (!isCollectionDetailsLoading && !isCollectionDetailsError && collectionDetails) {
            setPath(collectionDetails);
            setBoundary(location.state.boundry[0]);
        }
    }, [isCollectionDetailsLoading, isCollectionDetailsError, collectionDetails, location]);

    // Draw Boundary as Dark Gray Polygon and zoom in more
    useEffect(() => {
        if (mapInstance && googleMaps && boundary.length > 0) {
            const polygon = new googleMaps.Polygon({
                paths: boundary.map(point => ({ lat: point.x, lng: point.y })),
                strokeColor: '#505050',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#505050',
                fillOpacity: 0.1,
                map: mapInstance,
            });

            const bounds = new googleMaps.LatLngBounds();
            boundary.forEach(point => bounds.extend(new googleMaps.LatLng(point.lat, point.lng)));
            mapInstance.fitBounds(bounds);
       
            const listener = googleMaps.event.addListenerOnce(mapInstance, 'bounds_changed', () => {
                if (mapInstance.getZoom() > 16) {
                    mapInstance.setZoom(16); // Zoom in more within the boundary
                }
            });
            return () => googleMaps.event.removeListener(listener);
        }
    }, [boundary, mapInstance, googleMaps]);

    // Draw Path as Dark Blue Polyline
    useEffect(() => {
        if (mapInstance && googleMaps && path.length > 0) {
            const polyline = new googleMaps.Polyline({
                path: path.map(point => ({ lat: point.x, lng: point.y })),
                strokeColor: '#0000FF',
                strokeOpacity: 1.0,
                strokeWeight: 4,
                map: mapInstance,
            });

            const bounds = new googleMaps.LatLngBounds();
            path.forEach(point => bounds.extend(new googleMaps.LatLng(point.x, point.y)));
            mapInstance.fitBounds(bounds);
        }
    }, [path, mapInstance, googleMaps]);

    // Function to animate the truck marker along the path
    


    const animateTruck = () => {
        if (!isAnimating && truckMarker && path.length > 0) {
            let index = 0;
            const totalPoints = path.length;

            const intervalId = setInterval(() => {
                if (index < totalPoints) {
                    const currentPoint = path[index];
                    const nextPoint = path[index + 1] || currentPoint;

                    const latLng = new googleMaps.LatLng(currentPoint.x, currentPoint.y);
                    const heading = googleMaps.geometry.spherical.computeHeading(
                        new googleMaps.LatLng(currentPoint.x, currentPoint.y),
                        new googleMaps.LatLng(nextPoint.x, nextPoint.y)
                    );

                    // Update the position of the truck marker
                    truckMarker.setPosition(latLng);

                    // Update the truck marker icon to include rotation
                    truckMarker.setIcon({
                        url: getTruckIconUrl(heading), // Pass the heading to the icon function
                        scaledSize: new googleMaps.Size(40, 40),
                        anchor: new googleMaps.Point(20, 20),
                    });

                    index += 2; // Move to the next point
                } else {
                    clearInterval(intervalId); // Stop animation when end of path is reached
                    setIsAnimating(false);
                    setAnimationIntervalId(null);
                }
            }, 1000); // 1 second interval
            setAnimationIntervalId(intervalId);
        }
    };


    // Add truck marker and set its initial position to the start of the path
    useEffect(() => {
        if (mapInstance && googleMaps && path.length > 0) {
            const marker = new googleMaps.Marker({
                position: { lat: path[0].x, lng: path[0].y },
                map: mapInstance,
                icon: {
                    url: getTruckIconUrl(),
                    scaledSize: new googleMaps.Size(40, 40),
                    anchor: new googleMaps.Point(20, 20), // Ensure the marker's anchor is centered
                },
            });
            setTruckMarker(marker);
        }
    }, [path, mapInstance, googleMaps]);

    // Add start and end markers
    useEffect(() => {
        if (mapInstance && googleMaps) {
            // Start Location Marker
            new googleMaps.Marker({
                position: { lat: location.state.start_location.lat, lng: location.state.start_location.lng },
                map: mapInstance,
                label: 'S', // Label for Start
                icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    scaledSize: new googleMaps.Size(40, 40),
                },
            });

            // End Location Marker
            new googleMaps.Marker({
                position: { lat: location.state.end_location.lat, lng: location.state.end_location.lng },
                map: mapInstance,
                label: 'E', // Label for End
                icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    scaledSize: new googleMaps.Size(40, 40),
                },
            });
        }
    }, [mapInstance, googleMaps, location.state.start_location, location.state.end_location]);



    const getTruckIconUrl = (heading = 0) => {
        const color = '#4CAF50';
        const iconSvg = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200" width="100" height="200" transform="rotate(${heading})">
            <rect x="10" y="40" width="80" height="120" fill="${color}" stroke="black" stroke-width="2" />
            <rect x="20" y="10" width="60" height="30" fill="#333" stroke="black" stroke-width="2"/>
            <circle cx="20" cy="170" r="10" fill="black" />
            <circle cx="80" cy="170" r="10" fill="black" />
          </svg>`;
        return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`;
    };


    // Handle the start and stop of the animation
    const handleToggleAnimation = () => {
        if (isAnimating) {
            // Stop animation
            clearInterval(animationIntervalId);
            setIsAnimating(false);
            setAnimationIntervalId(null);
        } else {
            // Start animation
            setIsAnimating(true);
            animateTruck();
        }
    };

    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-md-12 d-flex justify-content-between align-items-center mb-2">
                    <h3>Live Location Map </h3>
                    <button className="btn btn-primary" onClick={handleToggleAnimation}>
                        {isAnimating ? 'Stop Animation' : 'Start Animation'}
                    </button>
                </div>
                <div className="col-md-12" style={{ height: '80vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: MAP_API_KEY }}
                        defaultCenter={DEFAULT_CENTER}
                        defaultZoom={DEFAULT_ZOOM}
                        yesIWantToUseGoogleMapApiInternals
                        center={mapCenter}
                        zoom={12}
                        onGoogleApiLoaded={({ map, maps }) => {
                            setMapInstance(map);
                            setGoogleMaps(maps);
                        }}
                        options={{
                            minZoom: 5,   // Set the minimum zoom level
                            maxZoom: 100,  // Set the maximum zoom level
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CollectionRoute;