import React from 'react';

const CollectionIEC_Person= () => {
    return (
        <div>
           CollectionIEC_Person
        </div>
    );
};

export default CollectionIEC_Person;
