import { useCollectionController } from "./collectionController";
import { useGetCollectionDetailsQuery } from './collectionRtk';
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";

export default function CollectionDetail() {
    const { id } = useParams();
    const [item, setItem] = useState(null);

    // Initialize navigate
    const navigate = useNavigate();

    // Destructure values from custom hook useCollectionController
    const {
        collectionList,
        isErrorCollectionList,
        isLoadingCollectionList,
        errorCollectionList,
        handleNavigate
    } = useCollectionController();

    console.log('collectionList11', collectionList, id);

    // Fetch the item from collectionList based on the id from URL params
    useEffect(() => {
        if (!isErrorCollectionList && !isLoadingCollectionList && collectionList && collectionList?.entities) {
            const foundItem = collectionList?.entities.find(item => item.assignment_id == id);
            setItem(foundItem);
        }
    }, [isErrorCollectionList, isLoadingCollectionList, collectionList, id]);



    // Navigate to map with collection details and boundary
    const handleNavigateToMap = () => {
        if (item?.boundry) {
            navigate('/b2g/collectionMap', { state: { id: id, collection_date: item.collection_date, boundry: item.boundry, start_location: item.start_location, end_location: item.end_location } });
        }
    }

    // Handle loading and error states for collection list
    if (isLoadingCollectionList) {
        return <div>Loading collection list...</div>;
    }
    if (isErrorCollectionList) {
        return <div>Error loading collection list: {errorCollectionList?.message}</div>;
    }

    if (!item) {
        return <div>Loadding ..</div>
    }

    // If both collectionList and collectionDetails are available
    return (
        <div className="container-fluid p-3">
            <div className="row d-flex justify-content-between p-1">
                <h4 className="col-5">Collection Details</h4>
                {item && item.status === 'Completed' && (
                    <button className="btn btn-primary col-4 col-sm-3 col-md-2 " onClick={handleNavigateToMap}>
                        View Route
                    </button>
                )}
            </div>
            <div className="row   mt-4">
                <p className="col-5">{`Status: ${item.status}`}</p>
                <p className="col-5">{`Category: ${item.category}`}</p>
                <p className="col-8">{`Collection Date: ${moment(item.collection_date).format('YYYY-MM-DD')}`}</p>
                <p className="col-5">{`Driver name: ${item.p_name}`}</p>
                <p className="col-5">{`Driver number: ${item.p_number}`}</p>
                <p className="col-5">{`Helper name: ${item.helper_name}`}</p>
                <p className="col-5">{`Helper number: ${item.helper_number}`}</p>
                <p className="col-5">{`Inc name: ${item.inc_name}`}</p>
                <p className="col-5">{`Inc number: ${item.inc_number}`}</p>
                <p className="col-8">{`Area: ${item.area_name}`}</p>
                <p className="col-5">{`Started at  : ${moment(item.start_time).format('YYYY-MM-DD | hh:mm')}`}</p>
                <p className="col-5">{`Completed at : ${moment(item.end_time).format('YYYY-MM-DD | hh:mm')}`}</p>
            </div>
        </div>
    );
}
